import React, { useState, useEffect } from "react"
import {
  Box,
  Container,
  Grid,
  Heading,
  Image,
  Flex,
  Button,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import AccordionClosed from "../../images/coesi-accordion-closed.svg"
import AccordionOpened from "../../images/coesi-accordion-open.svg"

const Accordion = ({ title, items }) => {
  const [selectedCategory, setSelectedCategory] = useState("Tutte")
  const [uniqueCategories, setUniqueCategories] = useState([])

  useEffect(() => {
    const categories = items.flatMap(item =>
      item.categories.map(category => ({
        title: category.title,
        position: category.position,
      }))
    )

    // Sort categories by position
    const sortedCategories = categories.sort((a, b) => a.position - b.position)

    // Extract titles and remove duplicates
    const uniqueCategoryTitles = [
      "Tutte",
      ...new Set(sortedCategories.map(category => category.title)),
    ]

    setUniqueCategories(uniqueCategoryTitles)
  }, [items])

  const handleCategoryClick = category => {
    setSelectedCategory(category)
  }

  const filteredItems =
    selectedCategory === "Tutte"
      ? items
      : items.filter(item =>
          item.categories.some(category =>
            selectedCategory.includes(category.title)
          )
        )

  return (
    <Box>
      {title && (
        <Container>
          <Grid columns={[1, 1, "2fr 3fr"]} gap={32}>
            <Box></Box>
            <Heading variant="h2">{title}</Heading>
          </Grid>
        </Container>
      )}
      <Box sx={{ backgroundColor: "dark" }}>
        <Container>
          <Flex sx={{ width: ["100%", "80%", "90%", "45%"], gap: 2 }}>
            {uniqueCategories.map(category => (
              <Button
                sx={{
                  padding: 2,
                  backgroundColor:
                    selectedCategory === category ? "white" : "dark",
                  color: selectedCategory === category ? "black" : "light",
                  border: "1px solid",
                  borderColor: "light",
                  ":hover": {
                    backgroundColor: "white",
                    color: "black",
                  },
                }}
                className={selectedCategory === category ? "active" : ""}
                key={category}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </Button>
            ))}
          </Flex>
        </Container>
      </Box>
      <AccordionWrapper allowMultipleExpanded={false} allowZeroExpanded={true}>
        {filteredItems.map((item, index) => (
          <Box
            sx={{
              borderTop: "1px solid",
              borderColor: "#666666",
              a: {
                color: "primary",
                "&:hover": {
                  color: "primary",
                },
              },
              p: {
                color: "dark",
              },
            }}
            key={item.id}
          >
            <AccordionItem>
              <AccordionItemState>
                {state => (
                  <>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Box
                          sx={{
                            cursor: "pointer",
                            backgroundColor: state.expanded ? "light" : "dark",
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                              backgroundColor: "light",
                              h3: { color: "primary" },
                            },
                          }}
                        >
                          <Container>
                            <Flex
                              sx={{
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Heading
                                  as="h3"
                                  variant="cooperative"
                                  sx={{
                                    letterSpacing: "1px",
                                    color: state.expanded ? "primary" : "light",
                                    mt: [0],
                                    mb: [0],
                                    letterSpacing: [0, "-1px"],
                                    fontWeight: "400",
                                  }}
                                >
                                  {item.title}
                                </Heading>
                              </Box>
                              <Flex
                                sx={{
                                  minWidth: ["100px"],
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                {state.expanded ? (
                                  <Image src={AccordionOpened} />
                                ) : (
                                  <Image src={AccordionClosed} />
                                )}
                              </Flex>
                            </Flex>
                          </Container>
                        </Box>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Box sx={{ backgroundColor: "light" }}>
                        <Container>
                          <Grid columns={[1, 1, 2]} gap={32}>
                            <RichContentStructuredText
                              text={item.body}
                              theme={"li"}
                            />
                            {item.images.length > 0 && (
                              <Flex
                                sx={{
                                  justifyContent: "flex-end",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Image src={item.images[0].url} alt="" />
                              </Flex>
                            )}
                          </Grid>
                        </Container>
                      </Box>
                    </AccordionItemPanel>
                  </>
                )}
              </AccordionItemState>
            </AccordionItem>
          </Box>
        ))}
      </AccordionWrapper>
    </Box>
  )
}

export default Accordion
