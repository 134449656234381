import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container } from "theme-ui"
const NumbersCollection = ({ title, image, numbers }) => {
  return (
    <Box sx={{ backgroundColor: "primary" }}>
      <Container sx={{ pt: [0, 0, 4, 8] }}>
        {title && (
          <Heading
            variant="kicker"
            sx={{
              borderBottom: ".5px solid",
              borderColor: "white",
              color: "light",
              pb: [2, 2, 4],
            }}
          >
            {title}
          </Heading>
        )}
        {image && <GatsbyImage image={image.gatsbyImageData} />}
        <Flex
          sx={{
            mt: [3, 3, 5, 7],
            mb: [4, 4, 5, 9],
            gap: "40px",
            flexDirection: ["column", "column", "row"],
          }}
        >
          {numbers.map(number => (
            <Number number={number} />
          ))}
        </Flex>
      </Container>
    </Box>
  )
}

const Number = ({ number }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Box sx={{ flex: "1" }} ref={ref}>
      <Heading
        variant="h2"
        sx={{
          mr: 0,
          fontWeight: 400,
          marginTop: 0,
          fontFamily: "acumin-pro-wide",
          fontSize: [9, 9, 7, "110px"],
          color: "white",
        }}
      >
        <CountUp
          end={number.float}
          prefix={number.prefix ? `${number.prefix} ` : null}
          start={startCount}
        />
        {number.suffix && (
          <Text sx={{ fontSize: [6, 7, 6, 6], marginLeft: "5px" }}>
            {" "}
            {` ${number.suffix}`}
          </Text>
        )}
      </Heading>
      <Text
        as="p"
        variant="caption"
        sx={{
          fontWeight: 800,
          fontSize: [5, 2, "24px"],
          width: "70%",
          mt: "40px",
        }}
      >
        {number.legend}
      </Text>
    </Box>
  )
}

export default NumbersCollection
