import React from "react"
import { graphql } from "gatsby"
import { Box } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHero from "./pageHero"
import { useFavicon } from "../hooks/useFavicon"
import ContactAccordion from "../components/blocks/contactAccordion"
import BigTitleAndBody from "../components/blocks/bigTitleAndBody"
import Accordion from "../components/blocks/accordion"
import NumbersCollection from "../components/blocks/numbersCollections"
import ImageCarousel from "../components/blocks/imageCarousel"
import ItemCarousel from "../components/blocks/itemCarousel"
import TitleAndBody from "../components/blocks/titleAndBody"
import PartnerShipCollection from "../components/blocks/partnerShipCollection"
const Page = ({ data: { page, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.content.map(block => (
        <Box
          as="section"
          sx={{
            backgroundColor: block.primary ? "primary" : "unset",
          }}
          key={block.id}
        >
          {block.model.apiKey === "big_title_and_body" && (
            <BigTitleAndBody block={block} light={block.light} />
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection title={block.title} numbers={block.numbers} />
          )}
          {block.model.apiKey === "title_and_body" && (
            <TitleAndBody
              block={block}
              light={block.light}
              primary={block.primary}
            />
          )}
          {block.model.apiKey === "partnership_collection" && (
            <PartnerShipCollection block={block} light={block.light} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel block={block} />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion items={block.items} />
          )}
          {block.model.apiKey === "contact_form" && (
            <Box>
              <ContactAccordion block={block} />
            </Box>
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsPartnershipCollection {
          id
          light
          model {
            apiKey
          }
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          partnerships {
            company {
              url
            }
            cooperative {
              url
            }
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          menuText
          light
          primary
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          bigTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          menuText
          menuText2
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }

          model {
            apiKey
          }
        }

        ... on DatoCmsNumbersCollection {
          id
          title
          model {
            apiKey
          }
          numbers {
            float
            prefix
            suffix
            legend
          }
        }
        ... on DatoCmsBigTitleAndBody {
          id
          menuText
          light
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          bigTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          open
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          menuText
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    images {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    categories {
      title
      position
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
        }
      }
      value
    }
    link {
      __typename
      ... on DatoCmsInternalLink {
        id: originalId
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
        }
      }
      ... on DatoCmsExternalLink {
        id: originalId
        anchor
        url
        model {
          apiKey
        }
      }
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    body {
      value
    }
    slug
    root
    model {
      apiKey
    }
    heroImage {
      alt
      title
      mimeType
      blurhash
      customData
      video {
        streamingUrl
        thumbnailUrl(format: jpg)
        mp4Url(exactRes: low)
        muxPlaybackId
      }
      gatsbyImageData(width: 1440, height: 900, placeholder: BLURRED)
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      alt
      title
    }
    model {
      apiKey
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
