import React from "react"
import {
  Box,
  Grid,
  Heading,
  Container,
  Flex,
  Text,
  Image,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const PartnerShipCollection = ({ block, light }) => {
  const { title, body, label } = block.content

  return (
    <Box
      sx={{
        pt: [4, 4, 8],
        pb: [4, 6, 7],
        backgroundColor: light ? "light" : "dark",
        color: light ? "dark" : "light",
      }}
    >
      <Container>
        {label && (
          <Text
            as="p"
            sx={{
              color: light ? "dark" : "light",
              borderBottom: ".5px solid",
              borderColor: light ? "dark" : "light",
            }}
            variant="kicker"
          >
            {label}
          </Text>
        )}

        <Grid
          columns={[1, 1, "1fr"]}
          gap={[4, 4, 4]}
          sx={{
            pt: [3],
          }}
        >
          <Box>
            <Heading
              as="h2"
              variant="h1"
              sx={{
                py: [3, 3, 7, 7],
                maxWidth: ["auto", "720px"],
                fontSize: [7, 7, 7, 9],
                mt: [0],
                mb: [0],
                color: light ? "dark" : "light",
                letterSpacing: [0, 0, "-3px"],
                fontWeight: "400",
              }}
              data-aos="fade-right"
              data-anchor-placement="left-bottom"
              data-aos-duration="1000"
            >
              {title}
            </Heading>
          </Box>
          <Flex
            sx={{
              width: "100%",
            }}
          >
            <Box sx={{ maxWidth: "520px", p: { fontSize: [4, 3] } }}>
              <RichContentStructuredText
                light={light}
                text={body}
                theme="dark"
              />
            </Box>
          </Flex>
          <Grid
            columns={[1, null, 5]}
            gap={2}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {block.partnerships.map((partner, index) => (
              <Flex
                sx={{
                  flexDirection: ["row", "row", "column"],
                  p: 4,
                  justifyContent: ["center", "center", "start"],
                  height: "250px",
                  border: "1px solid ",
                  borderColor: "#E2E2E2",
                }}
                key={index}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay={`${index * 100}`}
              >
                <Image
                  src={partner.cooperative.url}
                  alt="Cooperative Image"
                  sx={{ aspectRatio: 1 / 1, height: "100%" }}
                />
              </Flex>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default PartnerShipCollection
