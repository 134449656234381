import React from "react"
import { Box } from "theme-ui"
import CarouselImageAndText from "./carouselImageAndText"

const ItemCarousel = ({ block }) => {
  const { items } = block

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box sx={{ display: ["none", "none", "block"], overflow: "hidden" }}>
        <Box
          className="container"
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          {items.map((itemBlock, index) => (
            <Box
              as="section"
              className="panel"
              sx={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                margin: "0",
                padding: "0",
                width: "100vw",
                height: "100%",
              }}
              id={`${block.id}${index == 0 ? "menuText" : "menuText2"}`}
            >
              <CarouselImageAndText block={itemBlock} />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: ["block", "block", "none"] }}>
        {items.map((itemBlock, index) => (
          <section id={`${block.id}${index == 0 ? "menuText" : "menuText2"}`}>
            <CarouselImageAndText block={itemBlock} />
            <Box></Box>
          </section>
        ))}
      </Box>
    </Box>
  )
}

export default ItemCarousel
