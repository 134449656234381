import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockParallax from "../blockParallax"

const CarouselImageAndText = ({ block }) => {
  const { images } = block.content || block
  const { title, body, label, subtitle } = block.content || block
  const rightAligned = true

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "primary",
      }}
    >
      {images && images[0].gatsbyImageData && (
        <Box
          sx={{
            position: ["relative", "relative", "absolute"],
            width: ["100%", "100%"],
            maxWidth: ["100%", "100%", "36%"],
            height: "100%",
            top: 0,
            left: rightAligned ? null : 0,
            right: rightAligned ? 0 : null,
            ".gatsby-image-wrapper": {
              height: "100%",
            },
            overflow: "hidden",
          }}
        >
          <GatsbyImage image={images[0].gatsbyImageData} alt="" />
        </Box>
      )}
      <Container
        sx={{
          display: "flex",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Grid
          columns={[
            1,
            1,
            rightAligned ? "1fr 1fr" : "1fr 1fr",
            rightAligned ? "1fr 1fr" : "1fr 1fr",
          ]}
          gap={[0, 0, 32]}
        >
          {!rightAligned && <Box></Box>}
          <Flex
            sx={{
              flexDirection: "column",
            }}
          >
            <Box>
              <Box>
                <Heading
                  as="h3"
                  variant="kicker"
                  sx={{
                    mt: [0],
                    color: "light",
                    borderBottom: "1px solid",
                    borderColor: "light",
                    pb: [3],
                    maxWidth: "542px",
                  }}
                >
                  {title}
                </Heading>
              </Box>
            </Box>
            <Box
              sx={{
                my: [3, 4],
                "h1,h2,h3": {
                  color: "light",
                  mb: 5,
                  fontSize: [6, 8],
                  fontWeight: 300,
                },
                ul: {
                  maxWidth: "425px",
                  "li:not(:first-child)": {
                    borderTop: "1px solid",
                    borderColor: "rgba(255,255,255,.5)",
                    pt: [3],
                  },
                },
              }}
            >
              <RichContentStructuredText text={body} theme="dark" />
            </Box>
          </Flex>
          {rightAligned && <Box></Box>}
        </Grid>
      </Container>
    </Box>
  )
}

export default CarouselImageAndText
